import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Breadcrumb'
import SEO from 'components/SEO'

const StyledImg = styled(Img)`
  height: 100%;
  pointer-events: none;
  z-index: -1;
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 991px) {
    margin-top: -60px;
  }
`

const ImageContainer = styled.div`
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

function TermsofService({ pageContext }) {
  const { 
    contact: {
      title: pageTitle,
      path,
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage
            }
          }
        },
        content
      }
    },
    yoastMeta
  } = useStaticQuery(graphql`
    {
      contact: wordpressPage(wordpress_id: {eq: 403}) {
        title
        path
        acf {
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          content
        }
        yoastMeta: yoast_meta {
          name
          content
          property
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} />
      <div className="algemene-voorwaarden">
        <ImageContainer>
          <StyledImg className="d-flex" alt="" fluid={bannerImage} />
          <ImageContainerText className="container">
            <div className="title font-size-xxl font-weight-xl">
              <h2 className="color-text-light">Algemene voorwaarden</h2>
            </div>
          </ImageContainerText>
        </ImageContainer>
        <CustomBreadCrumb data={pageTitle} className="py-2" pageContext={pageContext} />
        <div className="container mt-2">
          <div className="row">
            <div className="col-lg-12" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
        <div className="d-lg-block d-none empty-space-300" />
      </div>
    </Layout>
  )
}

export default TermsofService